import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import Hero from "../comps/pledge/Hero";
import {graphql} from "gatsby";
import SimpleParagraph from "../comps/reusable/SimpleParagraph";
import Info from "../comps/pledge/Info";
import Investors from "../comps/pledge/Investors";

const Pledge = () => {

  return (
    <Layout>
      <Hero/>
      <SimpleParagraph nameSpace="ourPledge"/>
      <Info/>
      <Investors/>
    </Layout>
  );
}

export default styled(Pledge)`
`;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;