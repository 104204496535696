import React from "react";
import styled from "styled-components";
import { getFlex } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import stain1 from "../../images/stain/Stain1.png";
import stain2 from "../../images/stain/Stain2.png";
import stain3 from "../../images/stain/Stain3.png";
import stain4 from "../../images/stain/Stain4.png";

const Info = (props) => {
  const { className } = props;
  const { t } = useTranslation("ourPledge");

  return (
    <section className={className}>
      <div className="infoItem">
        <h2 className="h2-1">{t("infoFirstTitle")}</h2>
        <p>{t("infoFirstDesc")}</p>
      </div>
      <div className="infoItem">
        <h2 className="h2-2">{t("infoSecondTitle")}</h2>
        <p>{t("infoSecondDesc")}</p>
      </div>
      <div className="infoItem">
        <h2 className="h2-3">{t("infoThirdTitle")}</h2>
        <p>{t("infoThirdDesc")}</p>
      </div>
      <div className="infoItem">
        <h2 className="h2-4">{t("infoFourthTitle")}</h2>
        <p>{t("infoFourthDesc")}</p>
      </div>
    </section>
  );
};

export default styled(Info)`
  padding: 0 10% 4% 10%;
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 8rem;
  .infoItem {
    ${getFlex("column", "ct", "ct")};
    h2 {
      font-size: 2.4rem;
      text-align: center;
      width: fit-content;
      padding: 2rem 5rem;
    }

    p {
      font-size: 2rem;
      line-height: 3.2rem;
      font-family: SofiaProLightAz, sans-serif;
      text-align: center;
      width: 75%;
    }
    .h2-1 {
      background-image: url(${stain1});
      background-size: 6rem;
      background-attachment: local;
      background-repeat: no-repeat;
      background-position: 100% .5rem;
    }
    .h2-2 {
      background-image: url(${stain3});
      background-size: 6rem;
      background-attachment: local;
      background-repeat: no-repeat;
      background-position: 1rem .5rem;
    }
    .h2-3 {
      background-image: url(${stain4});
      background-size: 6rem;
      background-attachment: local;
      background-repeat: no-repeat;
      background-position: 92% .5rem;
    }
    .h2-4 {
      background-image: url(${stain2});
      background-size: 6rem;
      background-attachment: local;
      background-repeat: no-repeat;
      background-position: 45% .5rem;
    }
  }
`;
