import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { getFlex, palette } from "../../styles/styles";
import stain1 from "../../images/stain/Stain1.png";
import stain2 from "../../images/stain/Stain2.png";
import stain3 from "../../images/stain/Stain3.png";
import stain4 from "../../images/stain/Stain4.png";

const Hero = (props) => {
  const { className } = props;
  const { t } = useTranslation("ourPledge");

  return (
    <div className={className}>
      <section>
        <h1>{t("title")}</h1>
        <p>{t("desc")}</p>
      </section>
    </div>
  );
};

export default styled(Hero)`
  height: 70vh;
  background-image: url(${stain4}), url(${stain1});
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: 20rem, 25rem;
  background-position: 90vw 45rem, -5rem 48rem;
  section {
    padding: 0 16%;
    background-color: ${palette.blue};
    background-image: url(${stain2}), url(${stain3}), url(${stain4}), url(${stain1});
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: 25rem, 25rem, 20rem, 25rem;
    background-position: -10rem 12rem, 82vw -10rem, 90vw 45rem, -5rem 48rem;
    height: 90%;
    ${getFlex("column", "ct", "ct")};
    row-gap: 4rem;
    color: ${palette.whiteWhite};

    .bgHero {
      z-index: -1;
      position: absolute;
      max-height: 70vh;
    }

    h1 {
      font-size: 7.8rem;
      color: ${palette.whiteWhite};
      text-align: center;
    }

    p {
      font-size: 2.4rem;
      width: 75%;
      text-align: center;
      line-height: 2.8rem;
    }
  }
`;
